/* my imports for scss files */
@import "./assets/scss/custom/components/_selectfield.scss";
@import "./assets/scss/custom/pages/_applications.scss";
@import "./assets/scss/custom/pages/_staff.scss";
@import "./assets/scss/custom/pages/_sidechat.scss";

:root {
  /* --theme-color-primary: #7a6fbe; */
  /* theme colors */
  --theme-color-primary: #7ed957;
  --theme-color-secondary: #008037;
  
  /*  theme colors shades */
  --theme-color-primary-shadow: #7ed95749;
  --theme-color-secondary-shadow: #0080375f;

  /* general colors */
  --theme-color-white: #ffffff;
  --theme-color-black: #000000;
}
.text-primary-si {
  color: var(--theme-color-primary) !important;
}
.text-secondary-si {
  color: var(--theme-color-secondary) !important;
}
.background-primary-si {
  color: var(--theme-color-primary) !important;
}
.background-secondary-si {
  color: var(--theme-color-secondary) !important;
}

.btn-success{
  background-color: var(--theme-color-primary) !important;
  border-color: var(--theme-color-primary) !important;
  outline-color: var(--theme-color-primary) !important;
}

.btn-success-sec{
  background-color: var(--theme-color-secondary) !important;
  border-color: var(--theme-color-secondary) !important;
  outline-color: var(--theme-color-secondary) !important;
  color: white !important;
}

.bg-success{
  background-color: var(--theme-color-primary) !important;
}

.bg-success-pri{
  background-color: var(--theme-color-primary) !important;
}

.bg-success-sec{
  background-color: var(--theme-color-secondary) !important;
}

.form-control:focus {
  border-color: var(--theme-color-primary) !important;
  box-shadow: 0 0 0 0.25rem var(--theme-color-primary-shadow) !important;
}

.form-select:focus {
  border-color: var(--theme-color-primary) !important;
  box-shadow: 0 0 0 0.25rem var(--theme-color-primary-shadow) !important;
}

.dot-after::after {
  content: "";
  display: inline-block;
  width: 10px; /* Size of the circle */
  height: 10px; /* Size of the circle */
  border-radius: 50%; /* Makes the circle round */
  background-color: var(--theme-color-primary); /*   color */
  margin-left: 0.5rem;
}

.table-responsive {
  position: relative;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: transparent;
  z-index: 10; /* Higher value to overlay over other table elements */
}

/* .sticky-column + .sticky-column {
  left: 50px;
} */

.switch-btn {
  background: var(--theme-color-secondary);
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 6px;
  margin-right: 10px;
}

.switch-btn-sm {
  background: var(--theme-color-secondary);
  color: white;
  padding: 7px 18px;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 4px;
}

/*  to break a page on each section for download as a pdf */
.page-break {
  page-break-before: always;
}

.section-heading {
  page-break-inside: avoid;
  margin-bottom: 20px;
}

/* pagination custom stylying */
.pagination-link {
  color: var(--theme-color-secondary) !important;
  border-color: #dee2e6 !important;
}

/* pagination custom styling */
.pagination-link-active {
  background-color: var(--theme-color-secondary) !important;
  border-color: #dee2e6 !important;
}

.pagination-link:focus {
  outline: 2px solid #08780a57 !important;
}
.pagination-link-active:focus {
  outline: 2px solid #08780a57 !important;
}

/* input fields group */
.input-fields-group{
  border: 1px solid var(--theme-color-secondary);
  page-break-inside: avoid;
  border-radius: 7px;
  margin-inline: 1.5%;
  width: 97% !important;
  margin-bottom: 15px !important;
}

@media screen and (max-width: 480px) {
  .input-fields-group {
    margin: 4%;
    width: 92% !important;
  }
}

/* custom style for input view data  */
.input-view {
  padding: 6px 12px;
  border: 1px solid var(--theme-color-secondary);
  border-radius: 8px;
  min-height: 40px;
  inline-size: 100%;
  overflow-wrap: break-word;
  white-space: normal;
  word-break: break-word;
}


.c3-chart-arcs .c3-chart-arc .c3-text {
  fill: #4A90E2; /* Set your preferred color */
  font-weight: bold;
  font-size: 1.2em; /* Adjust font size as needed */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Optional: add shadow for readability */
}

/* select {
  background-color: white;
  color: black;
  border: 1px solid gray;
  padding: 5px;
}

select:hover {
  background-color: #7ed9577e;
  color: white;
  border: 1px solid blue;
} */

/* tooltip css */
.hover-text {
  position: relative;
  display: inline-block;
  text-align: center;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 125px;
  color: white;
  font-size: 15px;
  background-color: #000000b6;
  border-radius: 5px;
  padding: 8px;
  top: -40px;
  left: -50%;
}
/* end tooltip css */


::-webkit-scrollbar {
  width: 12px;
  height: 9px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(201, 220, 218);
}

::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}