@import "../../assets/scss/rtl/bootstrap-rtl.css";
@import "../../assets/scss/variables-shuk.scss";

.linkMainLi {
  &.linkMainLiActive {
    color: var(--theme-color-secondary) !important ;
    background-color: var(--bs-sidebar-menu-item-active-bg);
  }
  &:hover {
    color: var(--theme-color-primary) !important;
  }
}

.linkUlActive {
  color: var(--theme-color-secondary) !important;
}

.linkUl {
  padding-left: 48px;
  display: none;
  &.show {
    display: flex;
  }
  li {
    a {
      padding-top: 2px;
      padding-bottom: 2px;
      &:hover {
        color: var(--theme-color-primary) !important;
      }
    }
  }
}
.list-item {
  // transition: all .5s ease-in-out;
  animation: rotateX 0.7s ease-in-out;
}

.list-item:hover {
  background-color: #f8f9fa; /* Change background color on hover */
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    transform: translateY(-50deg);
  }
  100% {
    opacity: 1;
    transform: translateX(-100deg);
  }
}

// .expandable {
//   background: #fff;
//   overflow: hidden;
//   color: #000;
//   line-height: 50px;
//   transition: all .5s ease-in-out;
//   height: 0;
//  }

//  .expandable:active {
//   height: 100px;
// }
