.staff-table-container {
  .app-table-card {
    min-height: calc(100vh - 240px) !important;
  }
  .app-table-body {
    position: relative !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    min-height: calc(100vh - 440px) !important;
    max-height: calc(100vh - 440px) !important;
  }
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(201, 220, 218);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
