.input-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  cursor: pointer;
}
.input-image-browse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  background-color: var(--theme-color-primary) !important;
  height: 100%;
  color: white;
}
.input-image-name {
  display: flex;
  align-items: center;
  padding-left: 2px;
  width: 70%;
  background-color: white;
  height: 100%;
}
