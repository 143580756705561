.application-table-container{
  .app-table-card{
    min-height: calc(100vh - 160px) !important;
  }
  .app-table-body{
    position: relative !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    min-height: calc(100vh - 390px) !important;
    max-height: calc(100vh - 390px) !important;
  }
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(201, 220, 218);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.application-form-details{
  height: calc(100vh - 316px);
  overflow: auto;
}

.form-left-details{
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--theme-color-primary-shadow);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--theme-color-primary);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.application-comments-container {
  min-height: calc(100vh - 280px);
  .comments-body {
    overflow-y: auto;
    height: calc(100vh - 600px);
    padding: 1rem;
    background-color: hsl(0, 0%, 98%);
    border-radius: 8px;

    .comment-box {
      margin: 15px 0;
      padding: 15px;
      border: 1px solid var(--theme-color-secondary-shadow);
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;

      .comment-author-name {
        font-size: 16px;
        font-weight: bold;
        color: var(--theme-color-secondary);
        margin-bottom: 8px;
      }

      .comment-para {
        font-size: 15px;
        line-height: 1.5;
        color: #333;
        margin-bottom: 12px;
      }

      .comment-date {
        font-size: 12px;
        color: var(--theme-color-secondary);
        text-align: right;
        margin-top: 10px;
      }
    }
    .comment-box:hover {
      transform: scale(1.02);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }

  .comment-footer {
    height: 300px;
    background-color: #f8f9fa;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--theme-color-primary-shadow);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--theme-color-primary);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}


.custom-nav {
  display: flex;
  justify-content: center;
  margin-inline: 4px;
  overflow: hidden; 
  background-color: #f5f5f5; 
}

.custom-nav .nav-item {
  flex: 1; 
}

.custom-nav .nav-link {
  height: 100%;
  text-align: center;
  font-weight: 800;
  font-size: 12px;
  text-decoration: none;
  background-color: transparent; 
  transition: all 0.3s ease; 
}

.custom-nav .inactive-notes {
    color: var(--theme-color-secondary) !important; 
}

.custom-nav .inactive-chat {
    color: #cf2e2e;
}

.custom-nav .nav-link.active-notes {
  background-color: var(--theme-color-secondary) !important; 
  color: #fff; 
}

.custom-nav .nav-link.active-chat {
  background-color: #cf2e2e !important; 
  color: #fff; 
}