.chat-ui {
  display: flex;
  height: 70vh;
  font-family: Arial, sans-serif;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--theme-color-primary-shadow);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--theme-color-primary);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.user-list {
  width: 250px;
  background: #f5fff1;
  color: black;
  overflow-y: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-inline: 10px;
  margin-right: 10px;

  .hidden {
    display: none;
  }

  .visible {
    display: block;
  }

  h3 {
    text-align: center;
    padding: 10px;
    color: white;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .active {
      background: var(--theme-color-secondary);
      color: white;
      border-radius: 10px;
      a:visited,
      a:link {
        color: white !important;
      }
    }

    .non-active {
      a:visited,
      a:link {
        color: black;
      }
    }
  }

  .search-box {
    .form-control {
      border-radius: 30px;
      padding-left: 40px;
    }
    .search-icon {
      font-size: 18px;
      position: absolute;
      left: 13px;
      top: 0px;
      color: var(--#{$prefix}gray-600);
      line-height: 34px;
    }
  }
}

@media (max-width: 768px) {
  .user-list {
    position: absolute;
    z-index: 500000000000;
    left: calc(var(--bs-gutter-x) * 0.5);
    height: 76vh;
  }
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border-bottom: 1px solid var(--theme-color-primary);
}

.toggle-users {
  display: none;
  margin-right: 10px;
  font-size: 20px;
  background: none;
  color: black;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .toggle-users {
    display: inline-block;
  }
}

.message-box {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background: #ffffff;
}

.message {
  margin: 10px 0;
}

.message.self {
  text-align: right;
}

.message .sender {
  font-weight: bold;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.conversation-list {
  margin-bottom: 24px;
  position: relative;
  @media (min-width: 992px) {
    max-width: 60%;
  }

  .ctext-wrap {
    display: flex;
    margin-bottom: 20px;
  }

  .ctext-wrap-content {
    padding: 15px 20px;
    background-color: var(--#{$prefix}tertiary-bg);
    border-radius: 0px 10px 10px 10px;
    position: relative;

    p {
      font-size: 16px;
    }
  }

  .msg-time {
    color: rgb(97, 97, 97);
    float: right;
    margin: 6px;
  }

  .dropdown {
    .dropdown-toggle {
      font-size: 18px;
      padding: 4px;
      color: $gray-600;

      @media (max-width: 575.98px) {
        display: none;
      }
    }
  }

  .message-img {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    .message-img-list {
      position: relative;
    }

    img {
      max-width: 80px;
    }
  }
}

.right {
  display: flex;
  justify-content: flex-end;
  .conversation-list {
    text-align: left;

    .ctext-wrap {
      .ctext-wrap-content {
        order: 2;
        background-color: var(--theme-color-primary-shadow);
        text-align: left;
        border-radius: 10px 0px 10px 10px;
        color: black;
        margin-left: 0;

        &:before {
          border: 5px solid transparent;
          border-top-color: $primary;
          border-left-color: $primary;
          left: auto;
          right: -10px;
        }
      }

      .msg-time {
        color: rgb(97, 97, 97);
        float: right;
        margin: 6px;
      }

      .conversation-name {
        justify-content: flex-end;

        .time {
          order: 1;
          margin-left: 0;
          margin-right: 8px;
          position: absolute;
          right: -58px;
          left: auto;
        }

        .user-name {
          color: $dark;
          order: 2;
        }
      }
    }

    .dropdown {
      order: 1;
    }
    .chat-avatar {
      order: 3;
    }
  }
}

.chat-input {
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}

.chat-send {
  background-color: var(--theme-color-secondary) !important;
  border-color: var(--theme-color-secondary) !important;
  @media (max-width: 575.98px) {
    min-width: auto;
  }
}

