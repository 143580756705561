// 
// _table.scss
// 


.table {
  th {
      font-weight: $font-weight-bold;
  }
  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
      
  }
}


.table-bordered {
  border: $table-border-width solid $table-border-color;
}

// .table>:not(:first-child){
//   border-top: (0 * var(--#{$prefix}table-border-width)) solid $table-group-separator-color;
// }

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

// table card list

.table-card-list{
  border-collapse:separate; 
  border-spacing:0 12px; 
  tr{
    background-color: $card-bg;
  }
}

.table-responsive{
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($dark, .2);
    border-radius: 10px;
    border: 2px solid $white;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: $white; 
  }
}